import base64url from './base64url-arraybuffer'

// https://github.dev/NoobTW/WebAuthn/blob/master/server/src/index.js
function publicKeyCredentialToJSON(pubKeyCred) {
  if (pubKeyCred instanceof Array) {
    const arr = pubKeyCred.map(x => publicKeyCredentialToJSON(x))
    return arr
  }

  if (pubKeyCred instanceof ArrayBuffer) {
    return base64url.encode(pubKeyCred)
  }

  if (pubKeyCred instanceof Object) {
    const obj = {}

    for (const key in pubKeyCred) {
      obj[key] = publicKeyCredentialToJSON(pubKeyCred[key])
    }

    return obj
  }

  return pubKeyCred
}

export default publicKeyCredentialToJSON
