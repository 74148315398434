import React from 'react'
import { FontAwesomeIcon } from '@abs-warranty/react-fontawesome'
import { faCommentAltSmile } from '@abs-warranty/pro-solid-svg-icons/faCommentAltSmile'
import { faSignIn } from '@abs-warranty/pro-regular-svg-icons/faSignIn'
import { faSpinnerThird } from '@abs-warranty/pro-regular-svg-icons/faSpinnerThird'
import { faIdBadge } from '@abs-warranty/sharp-thin-svg-icons/faIdBadge'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { useTheme } from '../modules/theme'
import SignInWithMicrosoft from './sign-in-with-microsoft'
import { queryToString } from '../utils/query-to-string'
import { useForm } from 'react-hook-form'
import { useSubmit } from '../modules/submit'
import { InputEmail } from '@abs-warranty/next-components/form'
import base64url from '../utils/base64url-arraybuffer'
import publicKeyCredentialToJSON from '../utils/publickey-credentials-json'
import fetcher from '../utils/fetcher'
import { useError } from '../modules/error'

/**
 * Alternative submit button to sign in with a token
 *
 * @param {object} props
 * @param {boolean} [props.isSaving]
 * @returns {JSX.Element}
 */
function Btn({ isSaving }) {
  return (
    <Button className="shadow-sm" type="submit" disabled={isSaving}>
      <FontAwesomeIcon icon={isSaving ? faSpinnerThird : faSignIn} spin={isSaving} className="me-2" />
      Sign in
    </Button>
  )
}

function SignInCard() {
  const router = useRouter()
  const { isThemeDark } = useTheme()

  const { setError, Error } = useError()

  const [isClientSide, setIsClientSide] = useState(false)
  const [isShow, setIsShow] = useState(false)

  const {
    getValues,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const {
    onSubmit,
    SubmitBtn,
    Error: SubmitError,
  } = useSubmit({
    uri: {
      path: '/api/v3/auth/signin-passkey',
      method: 'POST',
      callback: async response => {
        // Get credentials from assertion
        const publicKey = { ...response }
        publicKey.challenge = base64url.decode(response.challenge)

        publicKey.allowCredentials.forEach(allowCred => {
          const ac = allowCred
          ac.id = base64url.decode(ac.id)
        })

        const credentials = await navigator.credentials.get({
          publicKey,
        })

        // Sign credentials and post to server
        const assertionResponse = publicKeyCredentialToJSON(credentials)

        try {
          await fetcher({
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...assertionResponse, email: getValues('email') }),
          })('/api/v3/auth/validate-passkey')

          router.replace(decodeURIComponent(queryToString(router.query.returnTo) ?? '/'))
        } catch (error) {
          setError({
            title: '😞',
            friendly: 'Unable to verify credentials',
            error,
          })
        }
      },
    },
    error: {
      title: '😞',
      friendly: 'Unable to sign in with credentials',
    },
  })

  // unfortunately necessary to prevent the sign in with microsoft button from incorrectly rendering server side
  useEffect(() => {
    // set timeout for a nice fade in effect while avoiding a flash of giant icons
    setTimeout(() => setIsClientSide(true), 250)
  }, [])

  return (
    <Row className="justify-content-center">
      <Col sm={6} className={`d-flex justify-content-center pt-5 fade ${isClientSide ? 'show' : ''}`}>
        <SubmitError />
        <Error />
        <Modal
          show={isShow}
          onHide={() => {
            setIsShow(false)
            reset()
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Sign in with credentials</Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body>
              <InputEmail name="email" label="Email" register={register} required autoFocus errors={errors} />
            </Modal.Body>
            <Modal.Footer>
              <SubmitBtn Btn={Btn} />
            </Modal.Footer>
          </Form>
        </Modal>
        <Card>
          <Card.Body className="text-center my-4">
            <Card.Title className="mb-4 display-6">
              <FontAwesomeIcon icon={faCommentAltSmile} className="me-2" />
            </Card.Title>
            <h1 className="mb-5 mx-5 display-6">Welcome back!</h1>
            <Card.Text className="d-flex flex-column">
              <a
                href={`/api/v3/auth/signin?returnTo=${encodeURIComponent(queryToString(router.query.returnTo) ?? '/')}`}
                className="mb-4"
              >
                {isClientSide && <SignInWithMicrosoft dark={isThemeDark} />}
              </a>
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <Button variant="link" className="text-muted p-0 m-0" onClick={() => setIsShow(true)}>
              <FontAwesomeIcon icon={faIdBadge} />
            </Button>
          </Card.Footer>
        </Card>
      </Col>
    </Row>
  )
}

export default SignInCard
